<template>
  <div
    v-once
    :id="elementId"
    class="hubspotForm"
    :data-hs-region="region"
    :data-hs-portal-id="portalId"
    :data-hs-form-id="formIdValue"
  ></div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue';

const { locale } = useI18n();

const { language } = useLanguage();

const props = defineProps({
  region: {
    type: String,
    default: 'na1'
  },
  portalId: {
    type: String,
    default: '23281212'
  },
  formId: {
    type: [Object, String] as PropType<
      | {
          en: string;
          es: string;
        }
      | string
    >,
    required: true
  },
  elementId: {
    type: String,
    default: 'hubspotForm'
  }
});

type Emits = {
  (e: 'submitted', form: unknown): void;
};

const emit = defineEmits<Emits>();

const { createForm } = useHubspot();

const formIdValue = ref(
  typeof props.formId === 'string'
    ? props.formId
    : props.formId[language.value as 'en' | 'es']
);

createForm({
  region: props.region,
  portalId: props.portalId,
  formId: formIdValue.value,
  target: '#' + props.elementId,
  onFormSubmitted: (form: unknown) => emit('submitted', form)
});
</script>
